import Vue from "vue";
import VueRouter from "vue-router";
import { IndexRoute } from './module/IndexRoute'
import { OrderRoute } from './module/OrderRoute'
import { UserRoute } from './module/UserRoute'
import { CenterRoute } from './module/CenterRoute'

Vue.use(VueRouter);

const routes = [
{
    path: "/",
    name: "Login",
    meta:{
        title:'登录'
    },
    component: () =>
        import("@/views/login.vue"),
},
{
    path: "/register",
    name: "Register",
    meta:{
        title:'注册'
    },
    component: () =>
        import("@/views/register.vue"),
},
{
    path: "/forgot",
    name: "Forgot",
    meta:{
        title:'忘记密码'
    },
    component: () =>
        import("@/views/forgot.vue"),
},
...IndexRoute,
...OrderRoute,
...UserRoute,
...CenterRoute
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;