export const CenterRoute = [{
    path: "/helpcenter",
    name: "HelpCenter",
    meta: {
        title: '帮助中心',
    },
    component: () => import("@/views/helpcenter"),
    // children: [{
    //     path: '/map',
    //     name: "Map",
    //     component: () => import("@/views/helpcenter/map"),
    //     meta: {
    //         title: '网站地图',
    //     },
    // }, ]
}]


