import Cache from '@/utils/cache'
import router from "@/router";
import api from "@/api";
const user = {
    namespaced: true,
    state: {
        userInfo: Cache.get("userInfo") || {},
        commonData: JSON.parse(sessionStorage.getItem("commonData")) || {},
        commonhotListData: sessionStorage.getItem("hotList") || [],
    },
    mutations: {
        SET_USER_INFO: (state, val) => {
            state.userInfo = val;
            Cache.set("userInfo", val)
            if (JSON.stringify(val) === "{}" || val === "") {
                router.replace({
                    path: '/'
                })
            }
        },
        SET_COMMON_DATA: (state, val) => {
            state.commonData = JSON.parse(val);
            // Cache.set("commonData", val)
            sessionStorage.setItem("commonData", val)
        },
        SET_HOT_LIST: (state, val) => {
            state.hotList = val;
            // Cache.set("commonData", val)
            sessionStorage.setItem("hotList", val)
        },
    },
    actions: {
        // login({commit},userInfo){
        // const username = userInfo.userInfo.trim()
        // const password = userInfo.userMenoryPwd ? userInfo.menoryPwd : sha1(userInfo.password.trim())
        // commit('SET_TOKEN',data.token.accessToken)
        // commit('SET_NAME',data.account.details.realName || '')
        // }
        async EXIT_LOGIN({
            commit
        }) {
            let {
                code
            } = await api.accountLogoutApi();
            if (code == 1) {
                commit("SET_USER_INFO", {});
                commit("SET_COMMON_DATA", {});
            }
        }
    }
}
export default user;