<template>
  <div class="row nr bread-crumb">
    所属分类：
    <el-breadcrumb
      separator-class="el-icon-arrow-right"
      v-if="breadCrumb"
    >
      <el-breadcrumb-item
        v-for="(item, index) in breadCrumb.list"
        :key="index"
        >{{ item.name }}</el-breadcrumb-item
      >
    </el-breadcrumb>
    <el-breadcrumb separator-class="el-icon-arrow-right" v-else>
      <el-breadcrumb-item
        v-for="(item, index) in breadList"
        :key="index"
        :to="{ path: item.path }"
        >{{ item.meta.title }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  props: {
    breadCrumb: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      breadList: [],
    };
  },
  created() {
      this.getBreadcrumb();
  },
  mounted() {
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  methods: {
    // 判断是否为首页
    isHome(route) {
      return route.name === "index";
    },
    getBreadcrumb() {
      let matched = this.$route.matched;
      if (!this.isHome(matched[0])) {
        matched = [{ path: "/index", meta: { title: "首页" } }].concat(matched);
      }

      this.breadList = matched;
    },
  },
};
</script>
<style lang="scss" scoped>
.bread-crumb {
  margin: 15px auto;
  color: #777777;
  line-height: 1;
  ::v-deep .el-breadcrumb {
    font-size: 16px;
    .el-breadcrumb__item {
      .el-breadcrumb__inner.is-link {
        color: inherit;
        font-weight: inherit;
        &:hover {
          color: $-color-primary;
        }
      }
      &:last-child {
        .el-breadcrumb__inner.is-link:hover {
          color: inherit;
        }
      }
    }
  }
}
</style>