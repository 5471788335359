export const IndexRoute = [{
    path: "/index",
    name: "index",
    meta: {
        title: '首页',
        tabCurrent: 0
    },
    component: () =>
        import("@/views/index/home"),
}, {
    path: "/discount",
    name: "discount",
    meta: {
        title: '限时折扣'
    },
    component: () =>
        import("@/views/index/discount"),
},  {
    path: "/reduction",
    name: "reduction",
    meta: {
        title: '促销满减'
    },
    component: () =>
        import("@/views/index/reduction"),
},{
    path: "/promake",
    name: "promake",
    meta: {
        title: '产品定制',
        tabCurrent: 5
    },
    component: () =>
        import("@/views/index/promake"),
}, {
    path: "/procurement",
    name: "procurement",
    meta: {
        title: '政府采购',
        customHeader: true
    },
    component: () =>
        import("@/views/index/procurement"),
}, {
    path: "/search",
    name: "search",
    meta: {
        title: '商品搜索'
    },
    component: () =>
        import("@/views/index/search"),
}, {
    path: "/prolist",
    name: "prolist",
    meta: {
        title: '产品列表'
    },
    component: () =>
        import("@/views/index/prolist"),
}, {
    path: "/prodetail",
    name: "prodetail",
    meta: {
        title: '产品详情'
    },
    component: () =>
        import("@/views/index/prodetail"),
}, {
    path: "/proall",
    name: "Proall",
    meta: {
        title: '全部产品',
        tabCurrent: 1
    },
    component: () =>
        import("@/views/index/proall"),
}]