<template>
  <header :class="{ minor: minor }">
    <div class="welcome row">
      <div class="core row-between lighter">
        <div class="welcome-l row">
          <div class="lang">
            您好<span class="primary" v-if="JSON.stringify(userInfo) != '{}'">
              {{ userInfo.nickname }} </span><span v-if="!commonData.mall_type">，欢迎来到{{ config.name }}！</span>
            <span class="cursor ml20" @click="toExit" v-if="JSON.stringify(userInfo) != '{}'">退出登录</span>
          </div>
          <div class="login-status">
            <div class="has" v-if="JSON.stringify(userInfo) == '{}'">
              <router-link to="/" class="lighter mr20">登录</router-link>
              <router-link to="/register" class="lighter">注册</router-link>
            </div>
          </div>
        </div>
        <div class="welcome-r row">
          <router-link to="/helpcenter" class="item row">
            <img class="mr10" src="@/assets/icon/icon-help.png" alt="" />
            <span>帮助中心</span>
          </router-link>
          <router-link to="/center" class="item row">
            <img class="mr10" src="@/assets/icon/icon-user.png" alt="" />
            <span>我的中心</span>
          </router-link>
          <router-link to="/orderlist" class="item row">
            <img class="mr10" src="@/assets/icon/icon-order.png" alt="" />
            <span>我的订单</span>
          </router-link>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="header-main">
      <div class="core">
        <div class="main-info row-between">
          <router-link to="/index" v-if="!commonData.mall_type">
            <img :src="minor ? config.help_center_logo : config.shop_login_logo" alt="" class="logo" />
          </router-link>
          <router-link to="/index" v-else>
            <img :src="commonData.logo" alt="" class="logo" />
          </router-link>
          <div class="info-content row">
            <slot name="selectBox"></slot>
            <ul class="nav-list white nr row" v-if="minor">
              <li class="nav-item">
                <router-link to="/index">首页</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/center">账户设置</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/evaluatelist">评价</router-link>
              </li>
              <li class="nav-item">
                <router-link to="/message">消息</router-link>
              </li>
            </ul>
            <div class="serach-box">
              <div class="search-t row mb5 bg-white">
                <!-- <img src="@/assets/icon/icon-search.png" alt="" /> -->
                <i class="iconfont lg primary">&#xe618;</i>
                <input type="text" :placeholder="hotList ? hotList[0] : '请输入关键词'" class="flex1"
                  v-model="serachKeyValue" />
                <button @click="serachHandle()" type="primary" class="white md">搜索</button>
              </div>
              <div class="search-b row xs" v-if="hotList">
                <span v-for="(item, index) in hotList.slice(1, hotList.length)" :key="index">
                  <router-link to="" class="warn">{{
                      item
                  }}</router-link>
                </span>
              </div>
            </div>
            <router-link to="/cart" class="cart-box row-center">
              <!-- <img src="@/assets/icon/icon-cart.png" alt="" /> -->
              <i class="iconfont lg primary mr10">&#xe61a;</i>
              <span class="sm lighter">购物车</span>
              <em class="bg-primary xxs white row-center" v-if="commonData.cart_num">{{ commonData.cart_num }}</em>
            </router-link>
            <!-- <div class="code column-center xs lighter">
              <img :src="config.app_qrcode" alt="" />
              下载APP
            </div> -->
          </div>
        </div>

        <div class="main-nav row-between">
          <div class="nav-l">
            <slot name="navList">
              <div class="nav-list row">
                <div class="nav-item category row bg-primary white" @mouseenter="
                  (classify = true),
                  current != null ? (classifyDetail = true) : false
                " @mouseleave="
  (classify = false),
  (classifyDetail = false),
  (current = null)
" @click="
  $router.push({
    path: '/prolist',
  })
">
                  <img src="@/assets/icon/icon-menu.png" alt="" class="menu mr20" />
                  <span class="md">产品分类</span>
                  <img src="@/assets/icon/icon-arrow.png" alt="" class="arrow" />
                </div>
                <router-link class="nav-item row-center" v-for="(item, index) in config.navigat_list" :key="index"
                  :class="{
                    'active primary': item.link == $route.path,
                  }" :to="item.link"><span>{{ item.name }}</span></router-link>
                <!-- <router-link class="nav-item row-center" :class="{
                  'active primary': $route.meta.tabCurrent == 0,
                }" to="/index"><span>首页</span></router-link>
                <router-link class="nav-item row-center" to="/procurement?type=gov" v-if="commonData.theme_type == 1">
                  <span>政府采购</span></router-link>
                <router-link class="nav-item row-center" to="/procurement?type=company"
                  v-if="commonData.theme_type == 1"><span>企业采购</span></router-link>
                <router-link class="nav-item row-center" to="/procurement?type=scene" v-if="commonData.theme_type == 1">
                  <span>场景采购</span></router-link>
                <router-link class="nav-item row-center" to="/promake" v-if="commonData.theme_type == 1" :class="{
                  'active primary': $route.meta.tabCurrent == 5,
                }"><span>产品定制</span></router-link> -->
                <!--<router-link
                  class="nav-item row-center"
                  :class="{
                    'active primary': $route.meta.tabCurrent == 1,
                  }"
                  to="/proall"
                  ><span>全部产品</span></router-link>-->
              </div>
            </slot>
          </div>
          <div class="nav-r row-center">
            <!-- <img src="@/assets/icon/icon-tel.png" alt="" class="" /> -->
            <i class="iconfont lg primary mr5">&#xe678;</i>
            <span class="nr muted">采购专线：{{ config.purchase_phone }}</span>
          </div>
        </div>
        <div class="core pr">
          <el-collapse-transition>
            <div class="nav-classify" v-show="classify">
              <ul>
                <li class="classify-item row" :class="{ active: current == index }"
                  v-for="(item, index) in categoryList" :key="index" @click="
                    $router.push({
                      path: '/prolist',
                      query: {
                        categoryOne: current + 1,
                        categoryId: item.id,
                      },
                    })
                  " @mouseenter="
  (classify = true),
  (classifyDetail = true),
  (current = index)
" @mouseleave="(classify = false), (classifyDetail = false)">
                  <!--<img
                    :src="current == index ? item.hover_image : item.image"
                    alt=""
                  />-->
                  <span>{{ item.name }}</span>
                </li>
              </ul>
            </div>
          </el-collapse-transition>
          <ul class="classify-detail" v-if="categoryList && classifyDetail && current != null"
            @mouseenter="(classify = true), (classifyDetail = true)"
            @mouseleave="(classify = false), (classifyDetail = false)">
            <li class="detail-item row md" v-for="(item, index) in categoryList[current].sons" :key="index">
              <router-link :to="`/prolist?categoryOne=${current + 1}&categoryTwo=${index + 1
              }&categoryId=${item.id}`" class="item-title normal">{{ item.name }}</router-link>
              <ul class="name-list pr row wrap lighter flex1">
                <li class="name-item" v-for="(items, indexs) in item.sons" :key="indexs">
                  <router-link :to="`/prolist?categoryOne=${current + 1}&categoryTwo=${index + 1
                  }&categoryThree=${indexs + 1}&categoryId=${items.id}`">{{ items.name }}</router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { mapGetters, mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      classify: false,
      classifyDetail: false,
      current: 0,
      categoryList: [], //头部分类列表
      serachKeyValue: "",
    };
  },
  props: {
    // 头部区分
    minor: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    current(val) { },
    userInfo: {
      handler(val) {
        this.$api.configApi().then((res) => {
          this.SET_CONFILG(res.data);
        });
        this.getCommonData();
      },
      deep: true,
    },

    // $route(val) {
    //   this.serachKeyValue = "";
    // },
  },
  created() {
    this.getCommonData();
    this.getCategory();
    this.getSearchList();
  },
  mounted() {
    let { head, keyword } = this.$route.query;
    if (head && head == 'search') {
      this.serachKeyValue = keyword
    }
  },
  methods: {
    ...mapMutations(["SET_CONFILG"]),
    ...mapMutations("user", ["SET_COMMON_DATA", "SET_HOT_LIST"]),
    /**
     * @description 获取大雨服务
     * @returns void
     */
    async getCommonData() {
      let commonData = {};
      await this.$api.commonDataApi().then((res) => {
        commonData = res.data;
        if (commonData.mall_type == 0) {
          document.getElementsByTagName("body")[0].className = "default";
        } else {
          document.getElementsByTagName("body")[0].className =
            commonData.mall_type == 1
              ? "three" : "two";
        }
        this.SET_COMMON_DATA(JSON.stringify(commonData));
        // document.getElementsByTagName("body")[0].className = "three";
      });
    },
    /**
     * @description 获取大雨分类
     * @returns void
     */
    getCategory() {
      this.$api.getCategoryApi().then((res) => {
        this.categoryList = res.data;
      });
    },

    serachHandle() {
      let key = this.serachKeyValue
      if (key == '') {
        this.$message({ message: '请输入商品关键字', type: "warning" });
      }
      this.$router.push({ path: "/search", query: { keyword: key, head: 'search' } });
    },
    /**
     * @description 前端个人中心订单
     * @returns void
     */
    toCenter() {
      this.$router.push({ path: "/order", query: { or: true } });
    },
    /**
     * @description 获取首页热门关键词
     * @return void
     */
    getSearchList() {
      this.$api.getSearchListApi().then((res) => {
        let { hot_lists } = res.data;
        this.SET_HOT_LIST(hot_lists);
      });
    },
    /**
     * @description 退出登录
     * @return void
     */
    toExit() {
      this.$confirm("请确认是否退出当前账号", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.$store.dispatch("user/EXIT_LOGIN");
        this.$message({
          type: "success",
          message: "退出成功",
        });
      });
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.config,
      commonData: (state) => state.user.commonData,
      hotList: (state) => state.user.hotList,
    }),
    ...mapGetters(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
header {
  position: relative;

  &.minor {

    .code,
    .search-b,
    .main-nav {
      display: none;
    }

    .cart-box {
      .bg-primary {
        background-color: #c81624;
      }

      margin-right: 0 !important;
    }

    .search-t {
      width: 305px !important;
      border: none !important;

      button {
        background-color: #f9f9f9;
        color: #999999;
      }
    }

    .header-main {
      background-color: $-color-primary;

      .logo {
        height: 84px !important;
      }
    }
  }

  &::after {
    content: "";
    height: 2px;
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    background-color: $-color-primary;
  }

  // 欢迎
  .welcome {
    background-color: #f5f5f5;
    height: 42px;

    .welcome-l {
      .lang {
        margin-right: 45px;
      }
    }

    .welcome-r {
      .item {
        margin-left: 32px;

        img {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  // 主要部分
  .header-main {
    background-color: #fff;

    .main-info {
      padding: 20px 0;

      .logo {
        width: auto;
        height: 95px;
      }

      .nav-list {
        height: 38px;
        margin-right: 30px;

        .nav-item {
          a {
            padding: 0 15px;
            display: block;

            &:hover {
              color: white !important;
            }
          }
        }
      }

      .info-content {
        padding-top: 18px;
        align-items: flex-start;

        .serach-box {
          margin-right: 13px;

          .search-t {
            border-width: 2px;
            border-color: $-color-primary;
            border-style: solid;
            height: 38px;
            width: 470px;

            .iconfont {
              margin: 0 13px;
            }

            button {
              height: 38px;
              width: 90px;
              position: relative;
              right: -1px;
            }
          }

          .search-b {
            height: 20px;

            span {
              margin-right: 15px;

              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        .cart-box {
          width: 121px;
          height: 38px;
          border: 1px solid #c0c0c0;
          background: #f9f9f9;
          position: relative;

          // margin-right: 35px;
          em {
            position: absolute;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            right: -4px;
            top: -4px;
          }
        }

        .code {
          width: 73px;
          height: 89px;
          border: 1px solid #eaeaea;

          img {
            width: 63px;
            height: 63px;
          }
        }
      }
    }

    // 导航
    .main-nav {
      .nav-l {
        cursor: pointer;

        .nav-list {
          height: 47px;

          ::v-deep .nav-item {
            height: 100%;
            padding: 0 30px;

            &.active {
              span {
                width: 100%;
                height: 100%;
                position: relative;
                display: block;
                line-height: 47px;

                &::before {
                  position: absolute;
                  content: "";
                  bottom: 0;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 0px;
                  height: 0px;
                  border-width: 6px;
                  border-style: solid;
                  border-color: transparent transparent $-color-primary transparent;
                }
              }
            }

            &.category {
              width: 221px;
              padding: 0 0 0 30px;

              .menu {
                width: 14px;
                height: 14px;
              }

              .arrow {
                width: 8px;
                height: 5px;
                margin-left: 42px;
              }
            }
          }
        }
      }

      .nav-r {
        img {
          width: 14px;
          height: 14px;
        }
      }
    }

    // 导航分类
    .nav-classify {
      cursor: pointer;
      position: absolute;
      left: 0;
      width: 221px;
      height: 600px;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 99;

      ul {
        height: 600px;
      }

      .classify-item {
        height: 70px;
        padding-left: 35px;
        color: $-color-white;

        // &.active:hover{
        //   color: $-color-primary;
        //   background-color: $-color-white;
        // }
        &.active,
        &:hover {
          color: $-color-primary;
          background-color: $-color-white;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
      }
    }

    .classify-detail {
      position: absolute;
      left: 221px;
      right: 0;
      height: 600px;
      z-index: 99;
      background-color: $-color-white;
      padding: 5px 40px;
      overflow-y: scroll;

      /* 设置滚动条的样式 */
      &::-webkit-scrollbar {
        width: 10px;
      }

      /* 滚动槽 */
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
        border-radius: 10px;
      }

      /* 滚动条滑块 */
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
      }

      &::-webkit-scrollbar-thumb:window-inactive {
        background: $-color-muted;
      }

      .detail-item {
        line-height: 1.7;
        align-items: flex-start;
        border-bottom: $-dashed-border;

        .item-title {
          //width: 81px;
          padding: 15px 15px 15px 0;
        }

        .name-list {
          padding: 15px 0;
          padding-left: 48px;

          &::after {
            content: "";
            position: absolute;
            width: 8px;
            height: 14px;
            top: 24px;
            left: 0;
            background-size: cover;
            background-image: url(~@/assets/icon/icon-arrow1.png);
          }

          .name-item {
            margin-right: 30px;
          }
        }
      }
    }
  }
}
</style>